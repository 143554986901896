<template>
  <sm-report :reportName="reportName" :breadcrumbs="breadcrumbs" />
</template>

<script>
import SmReport from '@/components/views/SmReport.vue';
export default {
  name: 'EmployeeCallDayReport',

  components: {
    SmReport,
  },

  data() {
    return {
      reportName: 'EmployeeCallDayReport',
      breadcrumbs: [
        {
          text: 'Колл-центр',
          route: { name: 'CallCenterMain' },
        },
        {
          text: 'Отчет по принятым звонкам сотрудников за день',
        },
      ],
    };
  },
};
</script>
